import React from "react";
import MicroFrontend from "../../MicroFrontend";
import { createBrowserHistory } from "history";
import Layout from "../../components/Layouts/Layout";
import "../style.scss";
const defaultHistory = createBrowserHistory();

const SnopFrontend = () => {
  const { REACT_APP_SNOP_HOST: snopHost } = process.env;

  function SnopModule({ history }) {
    return <MicroFrontend history={history} host={snopHost} name="snop" />;
  }

  return (
    <Layout>
      <div id="snop">
        <div>
          <SnopModule history={defaultHistory} />
        </div>
      </div>
    </Layout>
  );
};

export default SnopFrontend;
