import "font-awesome/css/font-awesome.min.css";
import {
  CartBoxesSvg,
  CommandCenterSvg,
  DigitalTwinSvg,
  DisruptionManagememntSvg,
  FileLinesSvg,
  IndustryWindowsSvg,
  ResilienceMonitoringSvg,
  SimulationWorldSvg,
  TruckSvg,
  WarehouseFullSvg
} from "../assets/svgIcons";
import {
  AnalyticsIcon,
  BudgetIcon,
  CollaborationIcon,
  ComputationsIcon,
  DataMappingIcon,
  DemandPlanning,
  DetailedSummaryIcon,
  EntitiesDataIcon,
  EquivalenceIcon,
  ExecutiveSummaryIcon,
  FeatureIcon,
  ForecastIcon,
  LocationIcon,
  MappingIcon,
  MerketingEventsIcon,
  NetworkIcon,
  PersonalIcon,
  ProductIcon,
  RawmaterialIcon,
  RmnodemappingIcon,
  SalesOrderIcon,
  SimulateIcon,
  SnopIcon,
  TransactionDataIcon
} from "../components/icons/";

const permissionSuffix = [
  "STATISTICS_VIEW",
  "PREPROCESS_VIEW",
  "JOB_SCH_VIEW",
  "DASHBOARD_VIEW",
  "JOB_SCH_CREATE",
  "JOB_HISTORY_VIEW",
  "JOB_SCH_UPDATE",
  "CONFIGURATION_VIEW",
  "CONFIGURATION_CREATE",
  "CONFIGURATION_UPDATE",
  "NETWORK_VIS_VIEW",
  "VIEW",
  "APP_GROUP_VIEW",
  "APP_ORG_VIEW"
];
const simulationSuffix = [
  "VIEW",
  "CREATE",
  "UPDATE",
  "DELETE",
  "CASE_UPDATE",
  "CASE_DELETE",
  "CASE_RUN",
  "CASE_FREEZE",
]

const createPermission = (prefix, ignore, preffixArray) => {
  return preffixArray.filter((suffix) => !ignore.includes(suffix)).map((suffix) => {
    return `${prefix}_${suffix}`
  })
}

//// Permissions Constants
const _permission = {
  CLIENT_VIEW: "CLIENT_VIEW",
  BU_VIEW: "BU_VIEW",
  BU_CONFIGURE: "BU_CONFIGURE",
  USER_VIEW: "USER_VIEW",
  ROLE_VIEW: "ROLE_VIEW",
  CLIENT_VIEWCONFIGURATION: "CLIENT_VIEWCONFIGURATION",
  FORECAST_VIEW: "FORECAST_VIEW",
  COLLABORATION_VIEW: "COLLABORATION_VIEW",
  DEMANDANALYTICS_VIEW: "DEMANDANALYTICS_VIEW",
  DPAI_REPORTS_VIEW: "DPAI_REPORTS_VIEW",
  PRODUCT_VIEW: "PRODUCT_VIEW",
  LOCATION_VIEW: "LOCATION_VIEW",
  MAPPING_VIEW: "MAPPING_VIEW",
  NETWORK_VIEW: "NETWORK_VIEW",
  PERSONNEL_VIEW: "PERSONNEL_VIEW",
  CURRENCY_VIEW: "CURRENCY_VIEW",
  VEHICLE_VIEW: "VEHICLE_VIEW",
  RAW_MATERIAL: "RAW_MATERIAL",
  RM_NODE_MAPPING: "RM_NODE_MAPPING",
  BUDGET_VIEW: "BUDGET_VIEW",
  MARKETING_EVENTS_VIEW: "MARKETING_EVENTS_VIEW",
  PROMOTION_TYPES_VIEW: "PROMOTION_TYPES_VIEW",
  SPLIT_VIEW: "SPLIT_VIEW",
  SUPPLY_PLAN_REPORTS_VIEW: "SUPPLY_PLAN_REPORTS_VIEW",
  SALESHISTORY_VIEW: "SALESHISTORY_VIEW",
  SALES_ORDER__VIEW: "SALES_ORDER__VIEW",
  FEATURES_VIEW: "FEATURES_VIEW",
  FORECASTSUMMARY_VIEW: "FORECASTSUMMARY_VIEW",
  GOODS_IN_TRANSIT: "GOODS_IN_TRANSIT",
  GOOD_IN_HAND: "GOOD_IN_HAND",
  GOODS_IN_PBNS: "GOODS_IN_PBNS",
  PRODUCTION_WORK_ORDER: "PRODUCTION_WORK_ORDER",
  BILL_OF_MATERIALS: "BILL_OF_MATERIALS",
  STOCK_TRANSFER_ORDER: "STOCK_TRANSFER_ORDER",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  ROW_MATERIAL_PURCHASE_ORDER: "ROW_MATERIAL_PURCHASE_ORDER",
  SHIPMENT: "SHIPMENT",
  TRANSACTION_LOG_VIEW: "TRANSACTION_LOG_VIEW",
  SNOP_VIEW: "SNOP_VIEW",
  SNOP_EDIT: "SNOP_EDIT",
  SNOP_CREATE: "SNOP_CREATE",
  SNOP_DELETE: "SNOP_DELETE",
  EXECUTIVE_SUMMARY_VIEW: "EXECUTIVE_SUMMARY_VIEW",
  DETAILED_SUMMARY_VIEW: "DETAILED_SUMMARY_VIEW",
  EQUIVALENCE_SUMMARY_VIEW: "EQUIVALENCE_SUMMARY_VIEW",
  COMPUTATION_SUMMARY_VIEW: "COMPUTATION_SUMMARY_VIEW",
  DATA_MAPPING_UPLOAD_VIEW: "DATA_MAPPING_UPLOAD_VIEW",
  RISK_DASHBOARD_VIEW: "RISK_DASHBOARD_VIEW",
  RISK_COMMAND_CENTRE_VIEW: "RISK_COMMAND_CENTRE_VIEW",
  RISK_DIGITAL_TWIN_VIEW: "RISK_DIGITAL_TWIN_VIEW",
  CLIENT_EDIT: "CLIENT_EDIT",
  BU_CREATE: "BU_CREATE",
  BU_EDIT: "BU_EDIT",
  BU_VIEWCONFIGURATION: "BU_VIEWCONFIGURATION",
  TEAM_VIEW: "TEAM_VIEW",
  TEAM_CREATE: "TEAM_CREATE",
  TEAM_EDIT: "TEAM_EDIT",
  USER_CREATE: "USER_CREATE",
  USER_EDIT: "USER_EDIT",
  ROLE_EDIT: "ROLE_EDIT",
  COMPUTATION_SCOPE_SUMMARY_VIEW: "COMPUTATION_SCOPE_SUMMARY_VIEW",
  SINGLEVIEW_SUMMARY_VIEW: "SINGLEVIEW_SUMMARY_VIEW",
  DATA_MAPPING_RULE_VIEW: "DATA_MAPPING_RULE_VIEW",
  GOODS_IN_HAND: "GOODS_IN_HAND",
  PROMO_MASTER_VIEW: "PROMO MASTER__VIEW",
  PROMO_TRANSACTION_VIEW: "PROMO_TRANSACTION_VIEW",
  RM_NETWORK_VIEW:"RM_NETWORK_VIEW",


  REPLENISHMENT_PLAN_STO_CREATE: "REPLENISHMENT_PLAN_STO_CREATE",
}
////
const SYSTEM_ADMIN = [
  _permission.CLIENT_VIEW,
  _permission.BU_VIEW,
  _permission.BU_CONFIGURE,
  _permission.USER_VIEW,
  _permission.ROLE_VIEW,
  _permission.CLIENT_VIEWCONFIGURATION
];

const DEMAND_PLANNING = [
  _permission.FORECAST_VIEW,
  _permission.COLLABORATION_VIEW,
  _permission.DEMANDANALYTICS_VIEW,
  _permission.DPAI_REPORTS_VIEW,
  ...createPermission("SIMULATION_SCENARIO", [], simulationSuffix),
];

export const PRODUCT_PLANNING = [
  "MARKETING_FORECAST_UPDATE",
  "MARKETING_FORECAST_REJECT",
  "MARKETING_FORECAST_APPROVE",
  "MARKETING_FORECAST_VIEW"
]

const INVENTORY_PLANNING = [
  ...createPermission("INVENTORY_MGMT", ["VIEW", "APP_GROUP_VIEW", "APP_ORG_VIEW"], permissionSuffix),
  ...createPermission("REPLENISHMENT_PLAN", ["DASHBOARD_VIEW", "STATISTICS_VIEW", "APP_GROUP_VIEW", "APP_ORG_VIEW"], permissionSuffix),
];

const PRODUCTION_PLANNING = [
  ...createPermission("PRODUCTION_RQMT", ["VIEW", "STATISTICS_VIEW", "APP_GROUP_VIEW", "APP_ORG_VIEW"], permissionSuffix),
  ...createPermission("PRODUCTION_PLAN", ["STATISTICS_VIEW", "DASHBOARD_VIEW"], permissionSuffix),
];

const RAW_MATERIAL_PLANNING = [
  ...createPermission("RM_RQMT", ["VIEW", "STATISTICS_VIEW", "JOB_HISTORY_VIEW", "APP_GROUP_VIEW", "APP_ORG_VIEW"], permissionSuffix),
  ...createPermission("RM_PLAN", ["STATISTICS_VIEW",
    "DASHBOARD_VIEW",
    "JOB_HISTORY_VIEW",
    "APP_GROUP_VIEW",
    "APP_ORG_VIEW"], permissionSuffix),
];

const SUPPLY_PLANNING = [
  ...INVENTORY_PLANNING,
  ...PRODUCTION_PLANNING,
  ...RAW_MATERIAL_PLANNING
];

const SUPPLY_PLAN_REPORTS = [_permission.SUPPLY_PLAN_REPORTS_VIEW];

const ENTITIES_DATA = [
  _permission.PRODUCT_VIEW,
  _permission.LOCATION_VIEW,
  _permission.MAPPING_VIEW,
  _permission.NETWORK_VIEW,
  _permission.PERSONNEL_VIEW,
  _permission.CURRENCY_VIEW,
  _permission.VEHICLE_VIEW,
  _permission.RAW_MATERIAL,
  _permission.RM_NODE_MAPPING,
  _permission.BUDGET_VIEW,
  _permission.MARKETING_EVENTS_VIEW,
  _permission.PROMOTION_TYPES_VIEW,
  _permission.SPLIT_VIEW,
  _permission.RM_NETWORK_VIEW,
];

const TRANSACTION_DATA = [
  _permission.SALESHISTORY_VIEW,
  _permission.SALES_ORDER__VIEW,
  _permission.FEATURES_VIEW,
  _permission.FORECASTSUMMARY_VIEW,
  "SCOPE_1_VIEW",
  "SCOPE_2_VIEW",
  "SCOPE_3_1_VIEW",
  "SCOPE_3_2_VIEW",
  "SCOPE_3_3_VIEW",
  "SCOPE_3_4_VIEW",
  "SCOPE_3_5_VIEW",
  "SCOPE_3_6_VIEW",
  "SCOPE_3_7_VIEW",
  "SCOPE_3_8_VIEW",
  "SCOPE_3_9_VIEW",
  "SCOPE_3_10_VIEW",
  "SCOPE_3_11_VIEW",
  "SCOPE_3_12_VIEW",
  "SCOPE_3_13_VIEW",
  "SCOPE_3_14_VIEW",
  "SCOPE_3_15_VIEW",
  _permission.GOODS_IN_TRANSIT,
  _permission.GOOD_IN_HAND,
  _permission.GOODS_IN_PBNS,
  _permission.PRODUCTION_WORK_ORDER,
  _permission.BILL_OF_MATERIALS,
  _permission.STOCK_TRANSFER_ORDER,
  _permission.PURCHASE_ORDER,
  _permission.ROW_MATERIAL_PURCHASE_ORDER,
  _permission.SHIPMENT,
];

const TRANSACTION_LOG = [_permission.TRANSACTION_LOG_VIEW];

const SNOP = [_permission.SNOP_VIEW, _permission.SNOP_EDIT, _permission.SNOP_CREATE, _permission.SNOP_DELETE];

const CARBON_ANALYTICS = [
  _permission.EXECUTIVE_SUMMARY_VIEW,
  _permission.DETAILED_SUMMARY_VIEW,
  _permission.EQUIVALENCE_SUMMARY_VIEW,
  _permission.COMPUTATION_SUMMARY_VIEW,
  _permission.DATA_MAPPING_UPLOAD_VIEW,
];

const DATA_MAPPING = [_permission.DATA_MAPPING_UPLOAD_VIEW];
const RISK_MANAGEMENT_DIGITAL_TWIN_DATA = [
  _permission.RISK_DASHBOARD_VIEW,
  _permission.RISK_COMMAND_CENTRE_VIEW,
  _permission.RISK_DIGITAL_TWIN_VIEW,
];

export const sideBarMenu = [
  {
    title: "Clients",
    icon: "fa fa-suitcase",
    path: "/client",
    permission: [_permission.CLIENT_VIEW],
    roles: [],
  },
  {
    title: "Setup",
    icon: "fa fa-archive",
    path: "/setup",
    permission: [_permission.BU_VIEW],
    roles: ["Client Admin"],
  },
  {
    title: "Configuration",
    icon: "fa fa-sliders",
    path: "/configuration",
    permission: [_permission.CLIENT_VIEWCONFIGURATION],
    roles: ["Client Admin"],
  },
  {
    title: "Users",
    icon: "fa fa-user-circle",
    path: "/user",
    permission: [_permission.USER_VIEW],
    roles: [],
  },
  {
    title: "Roles",
    icon: "fa fa-cog",
    path: "/role",
    permission: [_permission.ROLE_VIEW],
    roles: [],
  },
];

export const productMenu = [
  {
    title: "Forecasting",
    icon: ForecastIcon,
    path: "/pp/forecast",
    permission: ["MARKETING_FORECAST_VIEW"],
  },
]

export const demandMenu = [
  {
    title: "Forecasting",
    icon: ForecastIcon,
    path: "/dp/forecast",
    permission: [_permission.FORECAST_VIEW],
  },
  {
    title: "Collaboration",
    icon: CollaborationIcon,
    path: "/dp/collaboration",
    permission: [_permission.COLLABORATION_VIEW],
  },
  {
    title: "Analytics",
    icon: AnalyticsIcon,
    path: "/dp/analytics",
    permission: [_permission.DEMANDANALYTICS_VIEW],
  },
  {
    title: "Simulate",
    icon: SimulateIcon,
    path: "/dp/simulate/scenario",
    permission: ["SIMULATION_SCENARIO_VIEW"],
  },
  {
    title: "Reports",
    icon: SimulateIcon,
    path: "/dp/reports",
    permission: ["DPAI_REPORTS_VIEW"], //todo: update icon.
  },
];

export const carbonAnalyticsMenu = [
  {
    title: "Executive Summary",
    icon: ExecutiveSummaryIcon,
    path: "/carbon-analytics/executive-summary",
    permission: [_permission.EXECUTIVE_SUMMARY_VIEW],
  },
  {
    title: "Detailed Summary",
    icon: DetailedSummaryIcon,
    path: "/carbon-analytics/detailed-summary",
    permission: [_permission.DETAILED_SUMMARY_VIEW],
  },
  {
    title: "Equivalence",
    icon: EquivalenceIcon,
    path: "/carbon-analytics/equivalence",
    permission: [_permission.EQUIVALENCE_SUMMARY_VIEW],
  },
  {
    title: "Computation",
    icon: ComputationsIcon,
    path: "/carbon-analytics/computation",
    permission: [_permission.COMPUTATION_SUMMARY_VIEW],
  },
  {
    title: "Scope",
    icon: ComputationsIcon,
    // icon: "fa-home",
    path: "/carbon-analytics/computation/scope",
    permission: [_permission.COMPUTATION_SCOPE_SUMMARY_VIEW],
  },
  {
    title: "Single View",
    icon: ComputationsIcon,
    // icon: "fa-home",
    path: "/carbon-analytics/singleview",
    permission: [_permission.SINGLEVIEW_SUMMARY_VIEW],
  },
];

export const dataMappingMenu = [
  {
    title: "Upload",
    icon: ComputationsIcon,
    // icon: "fa-home",
    path: "/data-mapping-layer/upload",
    permission: [_permission.DATA_MAPPING_UPLOAD_VIEW],
  },
  {
    title: "Rule Selection",
    icon: ComputationsIcon,
    // icon: "fa-home",
    path: "/data-mapping-layer/rule-selection",
    permission: [_permission.DATA_MAPPING_RULE_VIEW],
  },
];

export const entitiesMenu = [
  {
    title: "Product",
    icon: ProductIcon,
    path: "/product",
    permission: [_permission.PRODUCT_VIEW],
  },
  {
    title: "Location",
    icon: LocationIcon,
    path: "/location",
    permission: [_permission.LOCATION_VIEW],
  },
  {
    title: "Mapping",
    icon: MappingIcon,
    path: "/mapping",
    permission: [_permission.MAPPING_VIEW],
  },
  {
    title: "Network",
    icon: NetworkIcon,
    path: "/network",
    permission: [_permission.NETWORK_VIEW],
  },
  {
    title: "Personnel",
    icon: PersonalIcon,
    path: "/personnel",
    permission: [_permission.PERSONNEL_VIEW],
  },
  // {
  //   title: "Currency",
  //   icon: CurrencyIcon,
  //   path: "/currency",
  //   permission: [_permission.PRODUCT_VIEW],
  // },
  {
    title: "Vehicle",
    icon: PersonalIcon,
    // icon: "fa-coins",
    path: "/entity-management/master/vehicle-entity",
    permission: [_permission.VEHICLE_VIEW],
  },
  {
    title: "Raw Material",
    icon: RawmaterialIcon,
    path: "/raw-material",
    permission: [_permission.RAW_MATERIAL],
  },
  {
    title: "RM Node Mapping",
    icon: RmnodemappingIcon,
    // icon: "fa-coins",
    path: "/rm-node-mapping",
    permission: [_permission.RM_NODE_MAPPING],
  },
  {
    title: "Budget",
    icon: BudgetIcon,
    path: "/budget",
    permission: [_permission.BUDGET_VIEW],
  },
  {
    title: "Marketing Events",
    icon: MerketingEventsIcon,
    path: "/marketing-events",
    permission: [_permission.MARKETING_EVENTS_VIEW],
  },
  {
    title: "Promotion Type",
    icon: FeatureIcon,
    path: "/promotion-type",
    permission: [_permission.PROMOTION_TYPES_VIEW],
  },
  {
    title: "Split",
    icon: FeatureIcon,
    path: "/splitmaster",
    permission: [_permission.SPLIT_VIEW],
  },
  {
    title: "Promotions",
    icon: FeatureIcon,
    path: "/promo-master",
    permission: [_permission.PROMO_MASTER_VIEW],
  },
{
    title: "RM Network",
    icon: FeatureIcon,
    path: "/rm-network",
    permission: [_permission.RM_NETWORK_VIEW],
  },
];

export const transactionMenu = [
  {
    title: "Actual Sales History",
    icon: FeatureIcon,
    // icon: "fa-history",
    path: "/actual-sales-history",
    permission: [_permission.SALESHISTORY_VIEW],
  },
  {
    title: "Sales Order",
    icon: SalesOrderIcon,
    // icon: "fa-history",
    path: "/sales-orders",
    permission: [_permission.SALES_ORDER__VIEW],
  },
  {
    title: "Feature",
    icon: FeatureIcon,
    path: "/feature",
    permission: [_permission.FEATURES_VIEW],
  },
  {
    title: "Forecast",
    icon: FeatureIcon,
    // icon: " fa-search",
    path: "/forecast-summary",
    permission: [_permission.FORECASTSUMMARY_VIEW],
  },
  {
    title: "Scope 1",
    icon: FeatureIcon,
    path: "/entity-management/transactional/scope-1",
    permission: ["SCOPE_1_VIEW"],
  },
  {
    title: "Scope 2",
    icon: FeatureIcon,
    path: "/entity-management/transactional/scope-2",
    permission: ["SCOPE_2_VIEW"],
  },
  {
    title: "Scope 3.1",
    icon: FeatureIcon,
    path: "/entity-management/transactional/scope-3-1",
    permission: ["SCOPE_3_1_VIEW"],
  },
  {
    title: "Scope 3.2",
    icon: FeatureIcon,
    path: "/entity-management/transactional/scope-3-2",
    permission: ["SCOPE_3_2_VIEW"],
  },
  {
    title: "Scope 3.3",
    icon: FeatureIcon,
    path: "/entity-management/transactional/scope-3-3",
    permission: ["SCOPE_3_3_VIEW"],
  },
  {
    title: "Scope 3.4",
    icon: FeatureIcon,
    path: "/entity-management/transactional/scope-3-4",
    permission: ["SCOPE_3_4_VIEW"],
  },
  {
    title: "Scope 3.5",
    icon: FeatureIcon,
    path: "/entity-management/transactional/scope-3-5",
    permission: ["SCOPE_3_5_VIEW"],
  },
  {
    title: "Scope 3.6",
    icon: FeatureIcon,
    path: "/entity-management/transactional/scope-3-6",
    permission: ["SCOPE_3_6_VIEW"],
  },
  {
    title: "Scope 3.7",
    icon: FeatureIcon,
    path: "/entity-management/transactional/scope-3-7",
    permission: ["SCOPE_3_7_VIEW"],
  },
  {
    title: "Scope 3.8",
    icon: FeatureIcon,
    path: "/entity-management/transactional/scope-3-8",
    permission: ["SCOPE_3_8_VIEW"],
  },
  {
    title: "Scope 3.9",
    icon: FeatureIcon,
    path: "/entity-management/transactional/scope-3-9",
    permission: ["SCOPE_3_9_VIEW"],
  },
  {
    title: "Scope 3.10",
    icon: FeatureIcon,
    path: "/entity-management/transactional/scope-3-10",
    permission: ["SCOPE_3_10_VIEW"],
  },
  {
    title: "Scope 3.11",
    icon: FeatureIcon,
    path: "/entity-management/transactional/scope-3-11",
    permission: ["SCOPE_3_11_VIEW"],
  },
  {
    title: "Scope 3.12",
    icon: FeatureIcon,
    path: "/entity-management/transactional/scope-3-12",
    permission: ["SCOPE_3_12_VIEW"],
  },
  {
    title: "Scope 3.13",
    icon: FeatureIcon,
    path: "/entity-management/transactional/scope-3-13",
    permission: ["SCOPE_3_13_VIEW"],
  },
  {
    title: "Scope 3.14",
    icon: FeatureIcon,
    path: "/entity-management/transactional/scope-3-14",
    permission: ["SCOPE_3_14_VIEW"],
  },
  {
    title: "Scope 3.15",
    icon: FeatureIcon,
    path: "/entity-management/transactional/scope-3-15",
    permission: ["SCOPE_3_15_VIEW"],
  },
  {
    title: "Goods In Transit",
    icon: FeatureIcon,
    path: "/goods-in-transit",
    permission: [_permission.GOODS_IN_TRANSIT],
  },
  {
    title: "Goods In Hand",
    icon: FeatureIcon,
    path: "/goods-in-hand",
    permission: [_permission.GOODS_IN_HAND],
  },
  {
    title: "Goods In PBNS",
    icon: FeatureIcon,
    path: "/goods-in-pbns",
    permission: [_permission.GOODS_IN_PBNS],
  },
  {
    title: "Production Work Order",
    icon: FeatureIcon,
    path: "/production-work-order",
    permission: [_permission.PRODUCTION_WORK_ORDER],
  },
  {
    title: "Bill Of Materials",
    icon: FeatureIcon,
    path: "/bill-of-materials",
    permission: [_permission.BILL_OF_MATERIALS],
  },
  {
    title: "Stock Transfer Order",
    icon: FeatureIcon,
    path: "/stock-transfer-order",
    permission: [_permission.STOCK_TRANSFER_ORDER],
  },
  {
    title: "Purchase Order",
    icon: FeatureIcon,
    path: "/purchase-order",
    permission: [_permission.PURCHASE_ORDER],
  },
  {
    title: "Raw Material Purchase Order",
    icon: FeatureIcon,
    path: "/rmpo",
    permission: [_permission.ROW_MATERIAL_PURCHASE_ORDER],
  },
  {
    title: "Shipment",
    icon: FeatureIcon,
    path: "/shipment",
    permission: [_permission.SHIPMENT],
  },
  {
    title: "Promotion Transactions",
    icon: FeatureIcon,
    path: "/promo-transaction",
    permission: [_permission.PROMO_TRANSACTION_VIEW],
  },
];

export const transactionLogMenu = [
  {
    title: "Transaction Log",
    icon: ProductIcon,
    path: "/transactionlog",
    permission: [_permission.TRANSACTION_LOG_VIEW],
  },
];

export const snopMenu = [
  {
    title: "S&OP",
    icon: SnopIcon,
    path: "/snop",
    permission: [_permission.SNOP_VIEW],
  },
];

export const additionalRoutes = [
  { path: "/client", permission: [_permission.CLIENT_VIEW], roles: [] },
  { path: "/setup", permission: [_permission.BU_VIEW], roles: [] },
  { path: "/client/edit", permission: [_permission.CLIENT_EDIT], roles: [] },
  { path: "/setup/create", permission: [_permission.BU_CREATE], roles: [] },
  { path: "/setup/edit", permission: [_permission.BU_EDIT], roles: [] },
  {
    path: "/setup/configuration",
    permission: [_permission.BU_VIEWCONFIGURATION],
    roles: [],
  },
  { path: "/setup/team", permission: [_permission.TEAM_VIEW], roles: [] },
  {
    path: "/setup/team/create",
    permission: [_permission.TEAM_CREATE],
    roles: [],
  },
  { path: "/setup/team/edit", permission: [_permission.TEAM_EDIT], roles: [] },
  { path: "/setup/team/user", permission: [_permission.USER_VIEW], roles: [] },
  { path: "/user/create", permission: [_permission.USER_CREATE], roles: [] },
  { path: "/user/edit", permission: [_permission.USER_EDIT], roles: [] },
  { path: "/role/edit", permission: [_permission.ROLE_EDIT], roles: [] },
  {
    path: "/dp/simulate/scenario/edit",
    permission: ["SIMULATION_SCENARIO_CREATE", "SIMULATION_SCENARIO_UPDATE"],
    roles: [],
  },
  {
    path: "/dp/simulate/scenario/create",
    permission: ["SIMULATION_SCENARIO_CREATE", "SIMULATION_SCENARIO_UPDATE"],
    roles: [],
  },
  {
    path: "/dp/simulate/scenario/case",
    permission: ["SIMULATION_SCENARIO_VIEW"],
    roles: [],
  },
  {
    path: "/dp/simulate/scenario/case/edit",
    permission: ["SIMULATION_SCENARIO_CASE_UPDATE"],
    roles: [],
  },
  {
    path: "/dp/simulate/scenario/case/create",
    permission: ["SIMULATION_SCENARIO_CREATE"],
    roles: [],
  },
  {
    path: "/dp/simulate/scenario/case/compare",
    permission: ["SIMULATION_SCENARIO_VIEW"],
    roles: [],
  },
  {
    path: "/dp/reports/details",
    permission: ["SIMULATION_SCENARIO_VIEW"],
    roles: [],
  },
  {
    path: "/snp/inventory/management/inventory-stats",
    permission: ["INVENTORY_MGMT_STATISTICS_VIEW"],
    roles: [],
  },
  {
    path: "/snp/inventory/management/preprocesses",
    permission: ["INVENTORY_MGMT_PREPROCESS_VIEW"],
    roles: [],
  },
  {
    path: "/snp/inventory/management/preprocesses/process/:type",
    permission: ["INVENTORY_MGMT_PREPROCESS_VIEW"],
    roles: [],
  },
  {
    path: "/snp/inventory/management/job-scheduler",
    permission: ["INVENTORY_MGMT_JOB_SCH_VIEW"],
    roles: [],
  },
  {
    path: "/snp/inventory/management/job-scheduler-create",
    permission: ["INVENTORY_MGMT_JOB_SCH_CREATE"],
    roles: [],
  },
  {
    path: "/snp/inventory/management/job-scheduler-history/:id",
    permission: ["INVENTORY_MGMT_JOB_HISTORY_VIEW"],
    roles: [],
  },
  {
    path: "/snp/inventory/management/job-scheduler-edit/:id",
    permission: ["INVENTORY_MGMT_JOB_SCH_UPDATE"],
    roles: [],
  },
  {
    path: "/snp/inventory/management/config",
    permission: ["INVENTORY_MGMT_CONFIGURATION_VIEW"],
    roles: [],
  },
  {
    path: "/snp/inventory/management/config-create",
    permission: ["INVENTORY_MGMT_CONFIGURATION_CREATE"],
    roles: [],
  },
  {
    path: "/snp/inventory/management/config-edit/:id",
    permission: ["INVENTORY_MGMT_CONFIGURATION_UPDATE"],
    roles: [],
  },
  {
    path: "/snp/inventory/management/network",
    permission: ["INVENTORY_MGMT_NETWORK_VIS_VIEW"],
    roles: [],
  },
  {
    path: "/snp/inventory/replenishment-plan/org-plan/:planId",
    permission: ["REPLENISHMENT_PLAN_VIEW"],
    roles: [],
  },
  {
    path: "/snp/inventory/replenishment-plan/group-plan/:planId",
    permission: ["REPLENISHMENT_PLAN_VIEW"],
    roles: [],
  },
  {
    path: "/snp/inventory/replenishment-plan/preprocesses",
    permission: ["REPLENISHMENT_PLAN_PREPROCESS_VIEW"],
    roles: [],
  },
  {
    path: "/snp/inventory/replenishment-plan/preprocesses/process/:type",
    permission: ["REPLENISHMENT_PLAN_PREPROCESS_VIEW"],
    roles: [],
  },
  {
    path: "/snp/inventory/replenishment-plan/job-scheduler",
    permission: ["REPLENISHMENT_PLAN_JOB_SCH_VIEW"],
    roles: [],
  },
  {
    path: "/snp/inventory/replenishment-plan/job-scheduler-create",
    permission: ["REPLENISHMENT_PLAN_JOB_SCH_CREATE"],
    roles: [],
  },
  {
    path: "/snp/inventory/replenishment-plan/job-scheduler-history/:id",
    permission: ["REPLENISHMENT_PLAN_JOB_HISTORY_VIEW"],
    roles: [],
  },
  {
    path: "/snp/inventory/replenishment-plan/job-scheduler-edit/:id",
    permission: ["REPLENISHMENT_PLAN_JOB_SCH_UPDATE"],
    roles: [],
  },
  {
    path: "/snp/inventory/replenishment-plan/config",
    permission: ["REPLENISHMENT_PLAN_CONFIGURATION_VIEW"],
    roles: [],
  },
  {
    path: "/snp/inventory/replenishment-plan/config-create",
    permission: ["REPLENISHMENT_PLAN_CONFIGURATION_CREATE"],
    roles: [],
  },
  {
    path: "/snp/inventory/replenishment-plan/config-edit/:id",
    permission: ["REPLENISHMENT_PLAN_CONFIGURATION_UPDATE"],
    roles: [],
  },
  {
    path: "/snp/inventory/replenishment-plan/network",
    permission: ["REPLENISHMENT_PLAN_NETWORK_VIS_VIEW"],
    roles: [],
  },
  {
    path: "/snp/production/requirement/preprocesses",
    permission: ["PRODUCTION_RQMT_PREPROCESS_VIEW"],
    roles: [],
  },
  {
    path: "/snp/production/requirement/job-scheduler",
    permission: ["PRODUCTION_RQMT_JOB_SCH_VIEW"],
    roles: [],
  },
  {
    path: "/snp/production/requirement/job-scheduler-create",
    permission: ["PRODUCTION_RQMT_JOB_SCH_CREATE"],
    roles: [],
  },
  {
    path: "/snp/production/requirement/job-scheduler-history:/id",
    permission: ["PRODUCTION_RQMT_JOB_HISTORY_VIEW"],
    roles: [],
  },
  {
    path: "/snp/production/requirement/job-scheduler-edit/:id",
    permission: ["PRODUCTION_RQMT_JOB_SCH_UPDATE"],
    roles: [],
  },
  {
    path: "/snp/production/requirement/config",
    permission: ["PRODUCTION_RQMT_CONFIGURATION_VIEW"],
    roles: [],
  },
  {
    path: "/snp/production/requirement/config-create",
    permission: ["PRODUCTION_RQMT_CONFIGURATION_CREATE"],
    roles: [],
  },
  {
    path: "/snp/production/requirement/config-edit/:id",
    permission: ["PRODUCTION_RQMT_CONFIGURATION_UPDATE"],
    roles: [],
  },
  {
    path: "/snp/production/requirement/network",
    permission: ["PRODUCTION_RQMT_NETWORK_VIS_VIEW"],
    roles: [],
  },
  {
    path: "/snp/production/production-plan/app-group-level-view/:id",
    permission: ["PRODUCTION_PLAN_APP_GROUP_VIEW"],
    roles: [],
  },
  {
    path: "/snp/production/production-plan/app-org-level-view/:id",
    permission: ["PRODUCTION_PLAN_APP_ORG_VIEW"],
    roles: [],
  },
  {
    path: "/snp/production/production-plan/app-group-level-view/:id/add-pwo",
    permission: ["PRODUCTION_PLAN_PWO_CREATE"],
    roles: [],
  },
  {
    path: "/snp/raw-material/requirement/preprocesses",
    permission: ["RM_RQMT_PREPROCESS_VIEW"],
    roles: [],
  },
  {
    path: "/snp/raw-material/requirement/job-scheduler",
    permission: ["RM_RQMT_JOB_SCH_VIEW"],
    roles: [],
  },
  {
    path: "/snp/raw-material/requirement/job-scheduler-create",
    permission: ["RM_RQMT_JOB_SCH_CREATE"],
    roles: [],
  },
  {
    path: "/snp/raw-material/requirement/job-scheduler-edit/:id",
    permission: ["RM_RQMT_JOB_SCH_UPDATE"],
    roles: [],
  },
  {
    path: "/snp/raw-material/requirement/config",
    permission: ["RM_RQMT_CONFIGURATION_VIEW"],
    roles: [],
  },
  {
    path: "/snp/raw-material/requirement/config-create",
    permission: ["RM_RQMT_CONFIGURATION_CREATE"],
    roles: [],
  },
  {
    path: "/snp/raw-material/requirement/config-edit/:id",
    permission: ["RM_RQMT_CONFIGURATION_UPDATE"],
    roles: [],
  },
  {
    path: "/snp/raw-material/requirement/network",
    permission: ["RM_RQMT_NETWORK_VIS_VIEW"],
    roles: [],
  },
  {
    path: "/snp/reports/:id",
    permission: [_permission.SUPPLY_PLAN_REPORTS_VIEW],
    roles: [],
  },
  {
    path: "/riskai/riskdetails",
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  },
  {
    path: "/dt/nodestats",
    permission: [_permission.RISK_DIGITAL_TWIN_VIEW],
    roles: [],
  },
  {
    path: "/dt/shipmentstats",
    permission: [_permission.RISK_DIGITAL_TWIN_VIEW],
    roles: [],
  },
  {
    path: "/snp/inventory/management/demand-overview",
    permission: ["INVENTORY_MGMT_STATISTICS_VIEW"],
    roles: [],
  },
  {
    path: "/snp/production/production-plan/preprocesses",
    permission: ["PRODUCTION_PLAN_PREPROCESS_VIEW"],
    roles: [],
  },
  {
    path: "/snp/production/production-plan/preprocesses/process/:type",
    permission: ["PRODUCTION_PLAN_PREPROCESS_VIEW"],
    roles: [],
  },
  {
    path: "/snp/production/production-plan/job-scheduler",
    permission: ["PRODUCTION_PLAN_JOB_SCH_VIEW"],
    roles: [],
  },
  {
    path: "/snp/production/production-plan/job-scheduler-create",
    permission: ["PRODUCTION_PLAN_JOB_SCH_CREATE"],
    roles: [],
  },
  {
    path: "/snp/production/production-plan/job-scheduler-history/:id",
    permission: ["PRODUCTION_PLAN_JOB_HISTORY_VIEW"],
    roles: [],
  },
  {
    path: "/snp/production/production-plan/job-scheduler-edit/:id",
    permission: ["PRODUCTION_PLAN_JOB_SCH_UPDATE"],
    roles: [],
  },
  {
    path: "/snp/production/production-plan/config",
    permission: ["PRODUCTION_PLAN_CONFIGURATION_VIEW"],
    roles: [],
  },
  {
    path: "/snp/production/production-plan/config-create",
    permission: ["PRODUCTION_PLAN_CONFIGURATION_CREATE"],
    roles: [],
  },
  {
    path: "/snp/production/production-plan/config-edit/:id",
    permission: ["PRODUCTION_PLAN_CONFIGURATION_UPDATE"],
    roles: [],
  },
  {
    path: "/snp/production/production-plan/network",
    permission: ["PRODUCTION_PLAN_NETWORK_VIS_VIEW"],
    roles: [],
  },
  {
    path: "/snp/raw-material/plan/preprocesses",
    permission: ["RM_PLAN_PREPROCESS_VIEW"],
    roles: [],
  },
  {
    path: "/snp/raw-material/plan/preprocesses/process/:type",
    permission: ["RM_PLAN_PREPROCESS_VIEW"],
    roles: [],
  },
  {
    path: "/snp/raw-material/plan/job-scheduler",
    permission: ["RM_PLAN_JOB_SCH_VIEW"],
    roles: [],
  },
  {
    path: "/snp/raw-material/plan/job-scheduler-create",
    permission: ["RM_PLAN_JOB_SCH_CREATE"],
    roles: [],
  },
  {
    path: "/snp/raw-material/plan/job-scheduler-edit/:id",
    permission: ["RM_PLAN_JOB_SCH_UPDATE"],
    roles: [],
  },
  {
    path: "/snp/raw-material/plan/config",
    permission: ["RM_PLAN_CONFIGURATION_VIEW"],
    roles: [],
  },
  {
    path: "/snp/raw-material/plan/config-create",
    permission: ["RM_PLAN_CONFIGURATION_CREATE"],
    roles: [],
  },
  {
    path: "/snp/raw-material/plan/config-edit/:id",
    permission: ["RM_PLAN_CONFIGURATION_UPDATE"],
    roles: [],
  },
  {
    path: "/snp/raw-material/plan/network",
    permission: ["RM_PLAN_NETWORK_VIS_VIEW"],
    roles: [],
  },
  {
    path: "/snp/inventory/replenishment-plan/group-plan/:planId/add-sto",
    permission: [_permission.REPLENISHMENT_PLAN_STO_CREATE],
    roles: [],
  },
  {
    path: '/supplier',
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  },
  {
    path: '/manufacturing',
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  },
  {
    path: '/transportation',
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  },
  {
    path: '/warehouse',
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  },
  {
    path: '/customer',
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  },
  {
    path: '/fginventory',
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  },
  {
    path: '/rminventory',
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  },
  {
    path: '/demand',
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  },
  {
    path: '/resilienceoverall/collab',
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  },
  {
    path: '/supplierdetail',
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  },
  {
    path: '/manufacturingdetail',
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  },
  {
    path: '/transportationdetail',
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  },
  {
    path: '/warehousedetail',
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  },
  {
    path: '/customerdetail',
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  },
  {
    path: '/rminventorydetail',
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  },
  {
    path: '/demanddetail',
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  },
  {
    path: '/gauri/chatresponse',
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  },
  {
    path: '/simulationresults',
    permission: [_permission.RISK_DASHBOARD_VIEW],
    roles: [],
  }
];

export {
  CARBON_ANALYTICS,
  DATA_MAPPING,
  DEMAND_PLANNING,
  ENTITIES_DATA,
  INVENTORY_PLANNING,
  PRODUCTION_PLANNING,
  RAW_MATERIAL_PLANNING,
  RISK_MANAGEMENT_DIGITAL_TWIN_DATA,
  SNOP,
  SUPPLY_PLANNING,
  SUPPLY_PLAN_REPORTS,
  SYSTEM_ADMIN,
  TRANSACTION_DATA,
  TRANSACTION_LOG
};

export const APPBAR_MENU = {
  IBP: "Integrated Business Planning",
  EDM: "Enterprise Data Management",
  ID: "Internal Dashboard",
  CX: "CarbonX",
  DTRM: "Risk Management & Digital Twin",
};

export const IBPMenu = [
  {
    title: "Product Planning",
    icon: DemandPlanning,
    subMenuPaths: [
      "/pp/forecast",
    ],
  },
  {
    title: "Demand Planning",
    icon: DemandPlanning,
    subMenuPaths: [
      "/dp/forecast",
      "/dp/collaboration",
      "/dp/analytics",
      "/dp/simulate/scenario",
      "/dp/reports",
      "/dp/simulate/scenario/case/compare",
      "/dp/simulate/scenario/case/create",
      "/dp/simulate/scenario/case/edit",
      "/dp/simulate/scenario/case",
      "/dp/simulate/scenario/edit",
      "/dp/simulate/scenario/create"
    ],
  },
  {
    title: "Supply Planning",
    icon: TruckSvg,
    subMenuPaths: [
      "/snp/inventory/management",
      "/snp/inventory/management/inventory-stats",
      "/snp/inventory/management/preprocesses",
      "/snp/inventory/management/preprocesses/process/:type",
      "/snp/inventory/management/job-scheduler",
      "/snp/inventory/management/job-scheduler-create",
      "/snp/inventory/management/job-scheduler-history/:id",
      "/snp/inventory/management/job-scheduler-edit/:id",
      "/snp/inventory/management/config",
      "/snp/inventory/management/config-create",
      "/snp/inventory/management/config-edit/:id",
      "/snp/inventory/management/network",
      "/snp/inventory/replenishment-plan",
      "/snp/inventory/replenishment-plan/org-plan/:planId",
      "/snp/inventory/replenishment-plan/group-plan/:planId",
      "/snp/inventory/replenishment-plan/preprocesses",
      "/snp/inventory/replenishment-plan/preprocesses/process/:type",
      "/snp/inventory/replenishment-plan/job-scheduler",
      "/snp/inventory/replenishment-plan/job-scheduler-create",
      "/snp/inventory/replenishment-plan/job-scheduler-history/:id",
      "/snp/inventory/replenishment-plan/job-scheduler-edit/:id",
      "/snp/inventory/replenishment-plan/config",
      "/snp/inventory/replenishment-plan/config-create",
      "/snp/inventory/replenishment-plan/config-edit/:id",
      "/snp/inventory/replenishment-plan/network",
      "/snp/production/requirement",
      "/snp/production/requirement/preprocesses",
      "/snp/production/requirement/job-scheduler",
      "/snp/production/requirement/job-scheduler-create",
      "/snp/production/requirement/job-scheduler-history/:id",
      "/snp/production/requirement/job-scheduler-edit/:id",
      "/snp/production/requirement/config",
      "/snp/production/requirement/config-create",
      "/snp/production/requirement/config-edit/:id",
      "/snp/production/requirement/network",
      "/snp/production/production-plan",
      "/snp/production/production-plan/app-group-level-view/:id",
      "/snp/production/production-plan/app-org-level-view/:id",
      "/snp/production/production-plan/app-group-level-view/:id/add-pwo",
      "/snp/raw-material/requirement",
      "/snp/raw-material/requirement/preprocesses",
      "/snp/raw-material/requirement/job-scheduler",
      "/snp/raw-material/requirement/job-scheduler-create",
      "/snp/raw-material/requirement/job-scheduler-edit/:id",
      "/snp/raw-material/requirement/config",
      "/snp/raw-material/requirement/config-create",
      "/snp/raw-material/requirement/config-edit/:id",
      "/snp/raw-material/requirement/network",
      "/snp/raw-material/plan",
      "/snp/reports",
      "/snp/reports/:id",
      "/snp/inventory/management/demand-overview",
      "/snp/production/production-plan/preprocesses",
      "/snp/production/production-plan/preprocesses/process/:type",
      "/snp/production/production-plan/job-scheduler",
      "/snp/production/production-plan/job-scheduler-create",
      "/snp/production/production-plan/job-scheduler-history/:id",
      "/snp/production/production-plan/job-scheduler-edit/:id",
      "/snp/production/production-plan/config",
      "/snp/production/production-plan/config-create",
      "/snp/production/production-plan/config-edit/:id",
      "/snp/production/production-plan/network",
      "/snp/raw-material/plan/preprocesses",
      "/snp/raw-material/plan/preprocesses/process/:type",
      "/snp/raw-material/plan/job-scheduler",
      "/snp/raw-material/plan/job-scheduler-create",
      "/snp/raw-material/plan/job-scheduler-edit/:id",
      "/snp/raw-material/plan/config",
      "/snp/raw-material/plan/config-create",
      "/snp/raw-material/plan/config-edit/:id",
      "/snp/raw-material/plan/network",
      "/snp/inventory/replenishment-plan/group-plan/:planId/add-sto"
    ],
  },
];

export const SUPPLYSubMenu = [
  {
    title: "Inventory",
    icon: WarehouseFullSvg,
    subMenuPaths: [
      "/snp/inventory/management",
      "/snp/inventory/management/inventory-stats",
      "/snp/inventory/management/preprocesses",
      "/snp/inventory/management/preprocesses/process/:type",
      "/snp/inventory/management/job-scheduler",
      "/snp/inventory/management/job-scheduler-create",
      "/snp/inventory/management/job-scheduler-history/:id",
      "/snp/inventory/management/job-scheduler-edit/:id",
      "/snp/inventory/management/config",
      "/snp/inventory/management/config-create",
      "/snp/inventory/management/config-edit/:id",
      "/snp/inventory/management/network",
      "/snp/inventory/replenishment-plan",
      "/snp/inventory/replenishment-plan/org-plan/:planId",
      "/snp/inventory/replenishment-plan/group-plan/:planId",
      "/snp/inventory/replenishment-plan/preprocesses",
      "/snp/inventory/replenishment-plan/preprocesses/process/:type",
      "/snp/inventory/replenishment-plan/job-scheduler",
      "/snp/inventory/replenishment-plan/job-scheduler-create",
      "/snp/inventory/replenishment-plan/job-scheduler-history/:id",
      "/snp/inventory/replenishment-plan/job-scheduler-edit/:id",
      "/snp/inventory/replenishment-plan/config",
      "/snp/inventory/replenishment-plan/config-create",
      "/snp/inventory/replenishment-plan/config-edit/:id",
      "/snp/inventory/replenishment-plan/network",
      "/snp/inventory/management/demand-overview",
      "/snp/inventory/replenishment-plan/group-plan/:planId/add-sto"
    ],
    permission: [],
  },
  {
    title: "Production",
    icon: IndustryWindowsSvg,
    subMenuPaths: [
      "/snp/production/requirement",
      "/snp/production/requirement/preprocesses",
      "/snp/production/requirement/job-scheduler",
      "/snp/production/requirement/job-scheduler-create",
      "/snp/production/requirement/job-scheduler-history/:id",
      "/snp/production/requirement/job-scheduler-edit/:id",
      "/snp/production/requirement/config",
      "/snp/production/requirement/config-create",
      "/snp/production/requirement/config-edit/:id",
      "/snp/production/requirement/network",
      "/snp/production/production-plan",
      "/snp/production/production-plan/app-group-level-view/:id",
      "/snp/production/production-plan/app-org-level-view/:id",
      "/snp/production/production-plan/app-group-level-view/:id/add-pwo",
      "/snp/production/production-plan/preprocesses",
      "/snp/production/production-plan/preprocesses/process/:type",
      "/snp/production/production-plan/job-scheduler",
      "/snp/production/production-plan/job-scheduler-create",
      "/snp/production/production-plan/job-scheduler-history/:id",
      "/snp/production/production-plan/job-scheduler-edit/:id",
      "/snp/production/production-plan/config",
      "/snp/production/production-plan/config-create",
      "/snp/production/production-plan/config-edit/:id",
      "/snp/production/production-plan/network"
    ],
    permission: [],
  },
  {
    title: "Raw Material",
    icon: CartBoxesSvg,
    subMenuPaths: [
      "/snp/raw-material/requirement",
      "/snp/raw-material/requirement/preprocesses",
      "/snp/raw-material/requirement/job-scheduler",
      "/snp/raw-material/requirement/job-scheduler-create",
      "/snp/raw-material/requirement/job-scheduler-edit/:id",
      "/snp/raw-material/requirement/config",
      "/snp/raw-material/requirement/config-create",
      "/snp/raw-material/requirement/config-edit/:id",
      "/snp/raw-material/requirement/network",
      "/snp/raw-material/plan",
      "/snp/raw-material/plan/preprocesses",
      "/snp/raw-material/plan/preprocesses/process/:type",
      "/snp/raw-material/plan/job-scheduler",
      "/snp/raw-material/plan/job-scheduler-create",
      "/snp/raw-material/plan/job-scheduler-edit/:id",
      "/snp/raw-material/plan/config",
      "/snp/raw-material/plan/config-create",
      "/snp/raw-material/plan/config-edit/:id",
      "/snp/raw-material/plan/network"
    ],
    permission: [],
  },
  {
    title: "Reports",
    icon: FileLinesSvg,
    subMenuPaths: ["/snp/reports", "/snp/reports/:id"],
    path: "/snp/reports",
    permission: [_permission.SUPPLY_PLAN_REPORTS_VIEW],
  },
];

export const EDMMenu = [
  {
    title: "Entities Data",
    icon: EntitiesDataIcon,
    subMenuPaths: [
      "/product",
      "/location",
      "/mapping",
      "/network",
      "/personnel",
      "/currency",
      "/entity-management/master/vehicle-entity",
      "/raw-material",
      "/rm-node-mapping",
      "/budget",
      "/marketing-events",
      "/promotion-type",
      "/splitmaster",
      "/promo-master",
      "/promo-transaction",
      "/rm-network",
    ],
  },
  {
    title: "Transaction Data",
    icon: TransactionDataIcon,
    subMenuPaths: [
      "/actual-sales-history",
      "/feature",
      "/forecast-summary",
      "/goods-in-transit",
      "/goods-in-hand",
      "/goods-in-pbns",
      "/production-work-order",
      "/bill-of-materials",
      "/stock-transfer-order",
      "/purchase-order",
      "/rmpo",
      "/shipment",
      "/sales-orders"
    ],
  },
  {
    title: "Transaction Log",
    icon: EntitiesDataIcon,
    subMenuPaths: ["/transactionlog"],
  },
];

export const CarbonXMenu = [
  {
    title: "Carbon Analytics",
    icon: AnalyticsIcon,
    subMenuPaths: [
      "/carbon-analytics/executive-summary",
      "/carbon-analytics/detailed-summary",
      "/carbon-analytics/equivalence",
      "/carbon-analytics/computation",
      "/carbon-analytics/computation/scope",
      "/carbon-analytics/singleview",
    ],
  },
  {
    title: "Data Mapping",
    icon: DataMappingIcon,
    subMenuPaths: [
      "/data-mapping-layer/upload",
      "/data-mapping-layer/rule-selection",
    ],
  },
];

export const inventoryMenu = [
  {
    title: "Inventory Management",
    permission: [
      ...createPermission("INVENTORY_MGMT", ["VIEW", "APP_GROUP_VIEW", "APP_ORG_VIEW"], permissionSuffix),
    ],
    path: "/snp/inventory/management",
  },
  {
    title: "Replenishment Plan",
    permission: [
      ...createPermission("REPLENISHMENT_PLAN", ["DASHBOARD_VIEW", "STATISTICS_VIEW", "APP_GROUP_VIEW", "APP_ORG_VIEW"], permissionSuffix),
    ],
    path: "/snp/inventory/replenishment-plan",
  },
];

export const productionMenu = [
  {
    title: "Production Requirement",
    permission: [
      ...createPermission("PRODUCTION_RQMT", ["VIEW", "STATISTICS_VIEW", "APP_GROUP_VIEW", "APP_ORG_VIEW"], permissionSuffix),
    ],
    path: "/snp/production/requirement",
  },
  {
    title: "Production Plan",
    permission: [
      ...createPermission("PRODUCTION_PLAN", ["STATISTICS_VIEW", "DASHBOARD_VIEW"], permissionSuffix),
    ],
    path: "/snp/production/production-plan",
  },
];

export const rawMaterialMenu = [
  {
    title: "RM Management",
    permission: ["RM_RQMT_DASHBOARD_VIEW"],
    path: "/snp/raw-material/requirement",
  },
  {
    title: "RM Plan",
    permission: [
      "RM_PLAN_VIEW",
      "RM_PLAN_PREPROCESS_VIEW",
      "RM_PLAN_JOB_SCH_VIEW",
      "RM_PLAN_JOB_SCH_CREATE",
      "RM_PLAN_JOB_SCH_UPDATE",
      "RM_PLAN_CONFIGURATION_VIEW",
      "RM_PLAN_CONFIGURATION_CREATE",
      "RM_PLAN_CONFIGURATION_UPDATE",
      "RM_PLAN_NETWORK_VIS_VIEW"
    ],
    path: "/snp/raw-material/plan",
  },
];

export const supply_reportMenu = [
  {
    title: "Reports",
    icon: FileLinesSvg,
    subMenuPaths: [],
    path: "/snp/reports",
    permission: [_permission.SUPPLY_PLAN_REPORTS_VIEW],
  },
];

export const riskDtrmMenu = [
  {
    title: "Command Center",
    icon: CommandCenterSvg,
    path: "/reports",
    permission: [_permission.RISK_DASHBOARD_VIEW],
  },
  {
    title: "Resilience Monitoring",
    icon: ResilienceMonitoringSvg,
    path: "/resilienceoverall",
    permission: [_permission.RISK_COMMAND_CENTRE_VIEW],
  },
  {
    title: "Disruption Management",
    icon: DisruptionManagememntSvg,
    path: "/riskai",
    permission: [_permission.RISK_COMMAND_CENTRE_VIEW],
  },
  {
    title: "GAURI",
    icon: CommandCenterSvg,
    path: "/gauri",
    permission: [_permission.RISK_COMMAND_CENTRE_VIEW],
  },
  {
    title: "Digital Twin",
    icon: DigitalTwinSvg,
    path: "/dt",
    permission: [_permission.RISK_DIGITAL_TWIN_VIEW],
  },
  {
    title: "Simulation World Engine",
    icon: SimulationWorldSvg,
    path: "/worldengine",
    permission: [_permission.RISK_COMMAND_CENTRE_VIEW],
  }
]

export const RiskAIMenu = [
  {
    title: "Risk AI",
    icon: DisruptionManagememntSvg,
    subMenuPaths: [
      '/riskai',
      '/riskai/riskdetails',
      '/reports',
      '/resilienceoverall',
      '/supplier',
      '/manufacturing',
      '/transportation',
      '/warehouse',
      '/customer',
      '/fginventory',
      '/rminventory',
      '/demand',
      '/resilienceoverall/collab',
      '/supplierdetail',
      '/manufacturingdetail',
      '/transportationdetail',
      '/warehousedetail',
      '/customerdetail',
      '/fginventorydetail',
      '/rminventorydetail',
      '/demanddetail',
      '/gauri',
      '/gauri/chatresponse',
      '/worldengine',
      '/simulationresults',
      '/dt',
      '/dt/nodestats',
      '/dt/shipmentstats'
    ],
  },
]

export const activateSNOPMessage = "Activate the SNOP to access";
export const mainMenus = [IBPMenu, EDMMenu, CarbonXMenu, RiskAIMenu];
export const URLSAFECHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";
export const MIN_LENGTH = 58;
export const HOST = process.env.REACT_APP_CLIENT_HOST;
