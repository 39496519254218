import React from "react";
import MicroFrontend from "../../MicroFrontend";
import { createBrowserHistory } from "history";
import Layout from "../../components/Layouts/Layout";
import "../style.scss";
const defaultHistory = createBrowserHistory();

const DpaiFrontend = () => {
  const { REACT_APP_DPAI_HOST: dpaiHost } = process.env;

  function DpaiModule({ history }) {
    return <MicroFrontend history={history} host={dpaiHost} name="dpai" />;
  }

  return (
    <Layout>
      <div id="dpai">
        <div>
          <DpaiModule history={defaultHistory} />
        </div>
      </div>
    </Layout>
  )
}
export default DpaiFrontend;
