import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import validator from "validator";
import companyLogo from "../../assets/3SC-logo.svg";
import { EnvelopeIcon } from "../../components/icons/EnvelopeIcon";
import InputField from "../../globalComponent/InputField";
import { forgotPassword } from "../../utils/ApiHandler";
import "./forgot-password.scss";
import Loader from "../../components/Loader";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [formData, SetFormData] = useState({
    email: "",
  });
  const { t } = useTranslation();

  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { email } = formData;

  useEffect(() => {
    if (email && !validator.isEmail(email)) setEmailError(true);
    else setEmailError(false);
  }, [email]);

  const onChange = (e) => {
    SetFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await forgotPassword(email);
    if (res?.status === 200) {
      toast.success("Password reset link sent to your mail ID !!!");
      navigate(`/`);
    }
    setLoading(false);
  };
  
  if(loading) return <Loader/>

  return (
    <div className="forgot-password-container">
      <div className="forgot-password">
        <div className="logo-container">
          <div className="logo-container-logo">
            <img src={companyLogo} alt="logo" className="logo-img" />
          </div>
        </div>
        <form className="forgot-password-form" onSubmit={onSubmit}>
          <h1 className="forgot-password-heading">{"Forgot Password?"}</h1>
          <span className="forgot-password-subheading">
            {"Send a link on your mail ID"}
          </span>
          <InputField
            className="forgot-password-input mb-0"
            containerClassName="input-email-address"
            id="outlined-basic"
            label={"Email ID"}
            variant="outlined"
            name="email"
            value={email}
            icon={[<EnvelopeIcon />]}
            helperText={
              emailError ? (
                <div style={{ color: "red" }} key="mail">
                  {t("emailAddressValidation")}
                </div>
              ) : null
            }
            onChange={(e) => onChange(e)}
          />

          <button
            className="change-password-button primary-button"
            disabled={emailError || !email || loading}
            type="submit"
          >
            {"Change Password"}
          </button>

          <div className="forgot-password-back">
            <Link to={"/"} className="remove-underline">
              <KeyboardBackspaceIcon />
              <span>{"Back to Login"}</span>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
