import React from "react";
export const EnvelopeIcon = () => {
  return (
    <svg viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.05 3.01505C32.0568 1.87306 31.078 0.95 29.8795 0.95H2.12713C0.928485 0.95 -0.05 1.87306 -0.05 3.01475V22.9852C-0.05 24.1269 0.928485 25.05 2.12713 25.05H29.8728C31.0714 25.05 32.05 24.127 32.05 22.9852V3.01505ZM32.05 3.01505L32 3.01475H32.05V3.01505ZM14.1215 15.5559L14.1215 15.5559L14.1224 15.5567C14.6251 16.0201 15.2974 16.2836 15.9967 16.2836C16.7023 16.2836 17.3881 16.0202 17.8912 15.5502L17.8918 15.5496L18.8063 14.6651L29.5356 23.6868H2.43843L12.8946 14.3692L14.1215 15.5559ZM15.1305 14.5865L2.39112 2.31319H29.6155L16.8896 14.5738C16.8894 14.5739 16.8893 14.574 16.8892 14.5741C16.4015 15.0235 15.6246 15.0296 15.1305 14.5865ZM1.38359 22.7599V3.28391L11.8822 13.3965L1.38359 22.7599ZM30.623 3.28387V22.7893L19.8187 13.686L30.623 3.28387Z"
        fill="#D9D9D9"
        stroke="#D9D9D9"
        stroke-width="0.1"
      />
    </svg>
  );
};
