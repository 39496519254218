import { isEmpty } from "lodash";
import { buId, snopId, tenantId } from "../config/localStorage";
import { getActiveSnop, getConfiguration } from "../utils/ApiHandler";
import {
    MIN_LENGTH,
    SNOP,
    URLSAFECHARS,
    carbonAnalyticsMenu,
    dataMappingMenu,
    demandMenu,
    entitiesMenu,
    inventoryMenu,
    productMenu,
    productionMenu,
    rawMaterialMenu,
    riskDtrmMenu,
    sideBarMenu,
    snopMenu,
    supply_reportMenu,
    transactionMenu
} from "../utils/Constants";

export const getPath = (permissions, roles) => {
    const resultArray = [];
    sideBarMenu.forEach((item) => {
        if (
            (item.permission.some((r) => permissions.includes(r)) &&
                item.roles.length === 0) ||
            roles.includes(item.roles[0])
        ) {
            resultArray.push(item);
        }
    });

    snopMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });

    demandMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });

    productMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });

    entitiesMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });

    transactionMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });
    carbonAnalyticsMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });
    dataMappingMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });
    riskDtrmMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });
    inventoryMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });
    productionMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });
    rawMaterialMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });
    supply_reportMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });
    return resultArray[0];
};

export const processLogin = async (res, setLoader, selected_business_unit) => {
    if (res?.status === 200) {
        const user = res.data.data;
        const path = !isEmpty(res) && getPath(user.permissions, user.roles);

        if (user.tenant_id && selected_business_unit?.business_unit_id) {
            const configurationResult = await getConfiguration(
                user.access,
                user.tenant_id,
                selected_business_unit?.business_unit_id
            );
            if (
                configurationResult?.status === 200 &&
                user.permissions.includes(SNOP[0])
            ) {
                await getActiveSnop(
                    user.access,
                    user.tenant_id,
                    selected_business_unit?.business_unit_id
                );
                window.location.replace(path.path);
            }
        } else {
            window.location.replace(path.path);
        }
    }
    setLoader(false);
};

export const generateUrlSafeString = () => {
    let result = "";
    const charsLength = URLSAFECHARS.length;
    for (let i = 0; i < MIN_LENGTH; i++) {
        const array = new Uint32Array(1);
        result += URLSAFECHARS.charAt(Math.floor(crypto.getRandomValues(array) % charsLength));
    }
    return result;
};

function arrayBufferToBase64Url(buffer) {
    const byteArray = new Uint8Array(buffer);
    const base64 = btoa(String.fromCharCode.apply(null, byteArray));
    return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/={0,100}$/, "");
}

// Function to compute SHA-256 hash and encode it as Base64URL
export async function sha256Base64UrlEncoded(data) {
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(data);

    const hashBuffer = await crypto.subtle.digest("SHA-256", encodedData);
    const base64UrlEncoded = arrayBufferToBase64Url(hashBuffer);

    return base64UrlEncoded;
}

export const isNotificationEnabled = () => {
    if (buId && tenantId && snopId) return true;
    return false;
}