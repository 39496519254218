import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout, tokenRefresh, tokenVerify } from "../utils/ApiHandler";
import {
  additionalRoutes,
  carbonAnalyticsMenu,
  dataMappingMenu,
  demandMenu,
  entitiesMenu,
  inventoryMenu,
  productMenu,
  productionMenu,
  rawMaterialMenu,
  riskDtrmMenu,
  sideBarMenu,
  snopMenu,
  supply_reportMenu,
  transactionLogMenu,
  transactionMenu
} from "../utils/Constants";
import Loader from "./../components/Loader/index";

const PrivateRoute = ({ children, path }) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const accessToken = JSON.parse(localStorage.getItem("token"));
  const refreshToken = JSON.parse(localStorage.getItem("refresh"));
  const [isTokenValid, setTokenValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validRoute, setValidRoute] = useState(false);
  const permissions = get(user, "permissions", []);

  const menuPaths = [
    ...sideBarMenu,
    ...demandMenu,
    ...entitiesMenu,
    ...transactionMenu,
    ...transactionLogMenu,
    ...snopMenu,
    ...additionalRoutes,
    ...carbonAnalyticsMenu,
    ...dataMappingMenu,
    ...inventoryMenu,
    ...productionMenu,
    ...rawMaterialMenu,
    ...supply_reportMenu,
    ...riskDtrmMenu,
    ...productMenu
  ];

  const _pathAccess = () => {
    const menu = menuPaths.find((el) => el.path === path);
    if (menu.permission.some((r) => permissions.includes(r))) {
      setValidRoute(true);
    }
  };

  const _refreshAccessToken = async () => {
    try {
      const authenticator = localStorage.getItem('authenticator');
      const res = await tokenRefresh(refreshToken, authenticator);
      if (res.status === 200) {
        localStorage.setItem("token", JSON.stringify(res.data.access));
        setTokenValid(true);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      if (
        typeof error.response.data === "undefined" ||
        typeof error.response.data.detail === "undefined"
      ) {
        toast.error(error.message);
      }
      toast.error(error.response.data?.detail);
      setLoading(false);
      setTokenValid(false);
      logout();
      navigate("/");
    }
  };

  const _verifyToken = async () => {
    try {
      const res = await tokenVerify(accessToken);
      if (res.status === 200) {
        setTokenValid(true);
        setLoading(false);
      }
    } catch (error) {
      _refreshAccessToken();
      console.error(error);
      setLoading(true);
    }
  };

  useEffect(() => {
    _verifyToken();
    _pathAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenValid]);

  if (loading) {
    return <Loader />;
  }

  if (validRoute) {
    return isTokenValid ? children : <Navigate to="/" />;
  } else {
    return <Navigate to="/error" />;
  }
};

export default PrivateRoute;
