import React from "react";
export const EyeSlashIcon = () => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_170_72)">
        <path
          d="M31.9361 15.6859C29.213 9.28355 22.9576 5.14648 16 5.14648C9.04244 5.14648 2.78712 9.28342 0.0639375 15.6859C-0.0213125 15.8863 -0.0213125 16.1126 0.0639375 16.313C2.78694 22.7161 9.04231 26.8535 16 26.8535C22.9579 26.8535 29.2132 22.7161 31.9361 16.313C32.0213 16.1126 32.0213 15.8863 31.9361 15.6859ZM16 25.2514C9.80037 25.2514 4.21519 21.6312 1.67556 15.9995C4.21525 10.3684 9.80056 6.74867 16 6.74867C22.1996 6.74867 27.7848 10.3684 30.3244 15.9994C27.7848 21.6311 22.1996 25.2514 16 25.2514Z"
          fill="#D9D9D9"
        />
        <path
          d="M16.0001 9.86426C12.6168 9.86426 9.86426 12.6168 9.86426 16.0001C9.86426 19.3834 12.6168 22.136 16.0001 22.136C19.3834 22.136 22.136 19.3835 22.136 16.0001C22.136 12.6168 19.3834 9.86426 16.0001 9.86426ZM16.0001 20.534C13.5003 20.534 11.4664 18.5001 11.4664 16.0003C11.4664 13.5004 13.5003 11.4665 16.0001 11.4665C18.5 11.4665 20.5339 13.5004 20.5339 16.0003C20.5339 18.5001 18.5 20.534 16.0001 20.534Z"
          fill="#D9D9D9"
        />
        <path
          d="M15.9999 12.7422C14.2037 12.7422 12.7422 14.2036 12.7422 15.9999C12.7422 16.4423 13.1008 16.8009 13.5433 16.8009C13.9858 16.8009 14.3443 16.4423 14.3443 15.9999C14.3443 15.087 15.0869 14.3443 15.9999 14.3443C16.4424 14.3443 16.8009 13.9856 16.8009 13.5433C16.8009 13.1008 16.4423 12.7422 15.9999 12.7422Z"
          fill="#D9D9D9"
        />
        <rect
          x="25.1648"
          y="1.96725"
          width="2.33333"
          height="34.3333"
          rx="1.16667"
          transform="rotate(36.7257 25.1648 1.96725)"
          fill="#D9D9D9"
          stroke="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_170_72">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
