import Divider from '@mui/material/Divider';
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import validator from "validator";
import companyLogo from "../../assets/3SC-logo.svg";
import { EnvelopeIcon } from "../../components/icons/EnvelopeIcon";
import { EyeIcon } from "../../components/icons/EyeIcon";
import { EyeSlashIcon } from "../../components/icons/EyeSlashIcon";
import InputField from "../../globalComponent/InputField";
import {
  generateUrlSafeString,
  getPath,
  processLogin,
  sha256Base64UrlEncoded
} from "../../helper/commonHelper";
import { authorizeOkta, loginUser, validateOKTALogin } from "../../utils/ApiHandler";
import { HOST } from '../../utils/Constants';
import ProcessingLoader from "./../../components/ProcessingLoader/index";
import "./Login.scss";

const Login = () => {
  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [path, setPath] = useState(window.location.href);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isEmpty(localStorage.getItem("user"))) {
      const user = JSON.parse(localStorage.getItem("user"));
      const permissions = get(user, "permissions", []);
      const roles = get(user, "roles", []);
      const path = getPath(permissions, roles);
      window.location.replace(path.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("user")]);

  useEffect(() => {
    if (path.includes("login")) {
      const str = path.slice(path.indexOf("=") + 1, path.indexOf("&"))
      if (str) {
        setLoader(true);
        window.history.pushState({}, null, HOST);

        (async () => {
          const res = await validateOKTALogin(str, localStorage.getItem('verifier'));
          const selected_business_unit = JSON.parse(localStorage.getItem("selected_business_unit") || null);
          processLogin(res, setLoader, selected_business_unit);
        })();
      }
    }
  }, [path])

  const [formData, SetFormData] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [emailError, setEmailError] = useState(false);

  const handleClickShowPassword = () => {
    SetFormData({
      ...formData,
      showPassword: !formData.showPassword,
    });
  };

  const { email, password, showPassword } = formData;

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      setLoader(true);
      submitUser(email, password);
    }
  };

  useEffect(() => {
    if (email && !validator.isEmail(email)) setEmailError(true);
    else setEmailError(false);
  }, [email]);

  const handleChange = (prop) => (event) => {
    SetFormData({ ...formData, [prop]: event.target.value });
  };

  const submitUser = async (email, password) => {
    try {
      setLoader(true);
      if (email === "" || password === "") {
        toast.error("Enter Email ID / Password !!!");
        setLoader(false);
      } else {
        const res = await loginUser(email, password);
        const selected_business_unit = JSON.parse(localStorage.getItem("selected_business_unit") || null);
        processLogin(res, setLoader, selected_business_unit);
      }
    } catch (error) {
      setLoader(false);
      let error_obj = JSON.parse(error.message);
      if (error_obj.error === "mfa_required") {
        if (error_obj.mfa_token !== "") {
          navigate("/login-mfa", {
            state: {
              oob_code: error_obj.oob_code,
              mfa_token: error_obj.mfa_token,
              email: error_obj.email,
            },
          });
        }
      }
    }
  };

  const onOKTAButtonClick = () => {
    const generatedString = generateUrlSafeString();
    localStorage.setItem('authenticator', "okta");
    localStorage.setItem('verifier', generatedString);
    sha256Base64UrlEncoded(generatedString)
      .then((encodedHash) => {
        localStorage.setItem('challenge', encodedHash);
        authorizeOkta(encodedHash);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <div className="login-container">
      {loader && <ProcessingLoader />}
      <div className="login">
        <div className="logo-container">
          <div className="logo-container-logo">
            <img src={companyLogo} alt="logo" className="logo-img" />
          </div>
        </div>
        <div className="login-form">
          <h1 className="login-heading">{"Welcome!"}</h1>
          <span className="login-subheading">{"Sign into your account"}</span>

          <InputField
            className="login-input mb-0"
            containerClassName="input-email-address"
            type="text"
            label={"Email ID"}
            variant="outlined"
            value={email}
            onChange={handleChange("email")}
            icon={[<EnvelopeIcon />]}
            helperText={
              emailError && (
                <div style={{ color: "red" }} key="mail">
                  {t("emailAddressValidation")}
                </div>
              )
            }
          />

          <InputField
            label={"Password"}
            variant="outlined"
            type={showPassword ? "text" : "password"}
            name="password"
            onKeyDown={(e) => {
              handleKeypress(e);
            }}
            icon={[
              <>
                {showPassword ? (
                  <span
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    <EyeSlashIcon />
                  </span>
                ) : (
                  <span
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    <EyeIcon />
                  </span>
                )}
              </>,
            ]}
            value={password}
            onChange={handleChange("password")}
            containerClassName="input-password"
          />
          <div className="login-forgot">
            <Link
              to={"/forgot-password"}
              className="remove-underline login-forgot-link"
            >
              <span>{"Forgot password?"}</span>
            </Link>
          </div>

          <button
            className="login-button"
            id="login-button"
            disabled={emailError || !password}
            onClick={() => {
              setLoader(true);
              submitUser(email, password);
            }}
          >
            {" "}
            {"Login"}{" "}
          </button>
          <div className="button-seperator">
            <Divider />
            <span> {"OR"} </span>
            <Divider />
          </div>
          <button
            className="login-button"
            onClick={() => {
              setLoader(true);
              onOKTAButtonClick();
            }}
          >
            {" "}
            {"Sign In with OKTA"}{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
