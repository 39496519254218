import React from "react";
export const EyeIcon = () => {
  return (
    <svg viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.9361 10.6859C29.213 4.28355 22.9576 0.146484 16 0.146484C9.04244 0.146484 2.78712 4.28342 0.0639375 10.6859C-0.0213125 10.8863 -0.0213125 11.1126 0.0639375 11.313C2.78694 17.7161 9.04231 21.8535 16 21.8535C22.9579 21.8535 29.2132 17.7161 31.9361 11.313C32.0213 11.1126 32.0213 10.8863 31.9361 10.6859ZM16 20.2514C9.80037 20.2514 4.21519 16.6312 1.67556 10.9995C4.21525 5.36842 9.80056 1.74867 16 1.74867C22.1996 1.74867 27.7848 5.36842 30.3244 10.9994C27.7848 16.6311 22.1996 20.2514 16 20.2514Z"
        fill="#D9D9D9"
      />
      <path
        d="M16.0001 4.86426C12.6168 4.86426 9.86426 7.61682 9.86426 11.0001C9.86426 14.3834 12.6168 17.136 16.0001 17.136C19.3834 17.136 22.136 14.3835 22.136 11.0001C22.136 7.61676 19.3834 4.86426 16.0001 4.86426ZM16.0001 15.534C13.5003 15.534 11.4664 13.5001 11.4664 11.0003C11.4664 8.50038 13.5003 6.46651 16.0001 6.46651C18.5 6.46651 20.5339 8.50038 20.5339 11.0003C20.5339 13.5001 18.5 15.534 16.0001 15.534Z"
        fill="#D9D9D9"
      />
      <path
        d="M15.9999 7.74219C14.2037 7.74219 12.7422 9.20356 12.7422 10.9999C12.7422 11.4423 13.1008 11.8009 13.5433 11.8009C13.9858 11.8009 14.3443 11.4423 14.3443 10.9999C14.3443 10.087 15.0869 9.34431 15.9999 9.34431C16.4424 9.34431 16.8009 8.98563 16.8009 8.54325C16.8009 8.10075 16.4423 7.74219 15.9999 7.74219Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};
