import React from "react";
import { Route, Routes } from "react-router-dom";
import ErrorPage from "../globalComponent/ErrorPage/Error";
import AdminFrontend from "../pages/AdminFrontend/AdminFrontend";
import CarbonFrontend from "../pages/CarbonFrontend/CarbonFrontend";
import DTFrontend from "../pages/DTFrontend";
import DpaiFrontend from "../pages/DpaiFrontend/DpaiFrontend";
import EdmFrontend from "../pages/EdmFrontend/EdmFrontend";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import Login from "../pages/Login/Login";
import OTP from "../pages/OTP";
import ProductFrontend from "../pages/ProductFrontend";
import RMFrontend from "../pages/RMFrontend";
import SetNewPassword from "../pages/SetNewPassword/SetNewPassword";
import SnpFrontend from "../pages/SnpFrontend/SnpFrontend";
import SnopFrontend from "../pages/snopFrontend";
import PrivateRoute from "./PrivateRoute";

const Routing = () => {
  return (
    <>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Login />} />
        <Route path="/login/callback" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/set-new-password" element={<SetNewPassword />} />
        <Route path="/login-mfa" element={<OTP />} />

        {/* Public routes */}

        {/* Internal Dashboard routes */}
        <Route path="/client" element={<PrivateRoute path="/client"><AdminFrontend />{" "}</PrivateRoute>} />
        <Route path="/configuration" element={<PrivateRoute path="/configuration"><AdminFrontend />{" "}</PrivateRoute>} />
        <Route path="/setup/create" element={<PrivateRoute path="/setup/create"><AdminFrontend />{" "}</PrivateRoute>} />
        <Route path="/setup/edit" element={<PrivateRoute path="/setup/edit"><AdminFrontend />{" "}</PrivateRoute>} />
        <Route path="/setup" element={<PrivateRoute path="/setup"><AdminFrontend />{" "}</PrivateRoute>} />
        <Route path="/setup/configuration" element={<PrivateRoute path="/setup/configuration"><AdminFrontend />{" "}</PrivateRoute>} />
        <Route path="/setup/team" element={<PrivateRoute path="/setup/team"><AdminFrontend />{" "}</PrivateRoute>} />
        <Route path="/setup/team/create" element={<PrivateRoute path="/setup/edit"><AdminFrontend />{" "}</PrivateRoute>} />
        <Route path="/setup/team/edit" element={<PrivateRoute path="/setup/team/edit"><AdminFrontend />{" "}</PrivateRoute>} />
        <Route path="/setup/team/user" element={<PrivateRoute path="/setup/team/user"><AdminFrontend />{" "}</PrivateRoute>} />
        <Route path="/user" element={<PrivateRoute path="/user"><AdminFrontend />{" "}</PrivateRoute>} />
        <Route path="/user/create" element={<PrivateRoute path="/user/create"><AdminFrontend />{" "}</PrivateRoute>} />
        <Route path="/user/edit" element={<PrivateRoute path="/user/edit"><AdminFrontend />{" "}</PrivateRoute>} />
        <Route path="/client/edit" element={<PrivateRoute path="/client/edit"><AdminFrontend />{" "}</PrivateRoute>} />
        <Route path="/role" element={<PrivateRoute path="/role"><AdminFrontend />{" "}</PrivateRoute>} />
        <Route path="/role/edit" element={<PrivateRoute path="/role/edit"><AdminFrontend />{" "}</PrivateRoute>} />
        {/* Internal Dashboard routes */}

        {/* EDM routes */}
        <Route path="/rm-network" element={<PrivateRoute path="/rm-network"><EdmFrontend />{" "}</PrivateRoute>} />

        <Route path="/product" element={<PrivateRoute path="/product"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/personnel" element={<PrivateRoute path="/personnel"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/mapping" element={<PrivateRoute path="/mapping"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/network" element={<PrivateRoute path="/network"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/location" element={<PrivateRoute path="/location"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/currency" element={<PrivateRoute path="/currency"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/actual-sales-history" element={<PrivateRoute path="/actual-sales-history"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/feature" element={<PrivateRoute path="/feature"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/forecast-summary" element={<PrivateRoute path="/forecast-summary"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/transactionlog" element={<PrivateRoute path="/transactionlog"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/goods-in-transit" element={<PrivateRoute path="/goods-in-transit"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/goods-in-hand" element={<PrivateRoute path="/goods-in-hand"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/goods-in-pbns" element={<PrivateRoute path="/goods-in-pbns"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/production-work-order" element={<PrivateRoute path="/production-work-order"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/bill-of-materials" element={<PrivateRoute path="/bill-of-materials"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/stock-transfer-order" element={<PrivateRoute path="/stock-transfer-order"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/raw-material" element={<PrivateRoute path="/raw-material"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/purchase-order" element={<PrivateRoute path="/purchase-order"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/rm-node-mapping" element={<PrivateRoute path="/rm-node-mapping"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/rmpo" element={<PrivateRoute path="/rmpo"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/shipment" element={<PrivateRoute path="/shipment"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/promo-transaction" element={<PrivateRoute path="/promo-transaction"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/promo-master" element={<PrivateRoute path="/promo-master"><EdmFrontend />{" "}</PrivateRoute>} />

        <Route path="/budget" element={<PrivateRoute path="/budget"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/marketing-events" element={<PrivateRoute path="/marketing-events"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/promotion-type" element={<PrivateRoute path="/promotion-type"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/splitmaster" element={<PrivateRoute path="/splitmaster"><EdmFrontend />{" "}</PrivateRoute>} />
        <Route path="/sales-orders" element={<PrivateRoute path="/sales-orders"><EdmFrontend />{" "}</PrivateRoute>} />
        {/* EDM routes */}

        {/* SNOP routes */}
        <Route path="/snop" element={<PrivateRoute path="/snop"><SnopFrontend />{" "}</PrivateRoute>} />
        {/* SNOP routes */}

        {/* DPAI routes */}
        <Route path="/dp/forecast" element={<PrivateRoute path="/dp/forecast"><DpaiFrontend />{" "}</PrivateRoute>} />
        <Route path="/dp/analytics" element={<PrivateRoute path="/dp/analytics"><DpaiFrontend />{" "}</PrivateRoute>} />
        <Route path="/dp/collaboration" element={<PrivateRoute path="/dp/collaboration"><DpaiFrontend />{" "}</PrivateRoute>} />
        <Route path="/dp/simulate/scenario" element={<PrivateRoute path="/dp/simulate/scenario"><DpaiFrontend />{" "}</PrivateRoute>} />
        <Route path="/dp/simulate/scenario/edit" element={<PrivateRoute path="/dp/simulate/scenario/edit"><DpaiFrontend />{" "}</PrivateRoute>} />
        <Route path="/dp/simulate/scenario/create" element={<PrivateRoute path="/dp/simulate/scenario/create"><DpaiFrontend />{" "}</PrivateRoute>} />
        <Route path="/dp/simulate/scenario/case" element={<PrivateRoute path="/dp/simulate/scenario/case"><DpaiFrontend />{" "}</PrivateRoute>} />
        <Route path="/dp/simulate/scenario/case/create" element={<PrivateRoute path="/dp/simulate/scenario/case/create"><DpaiFrontend />{" "}</PrivateRoute>} />
        <Route path="/dp/simulate/scenario/case/edit" element={<PrivateRoute path="/dp/simulate/scenario/case/edit"><DpaiFrontend />{" "}</PrivateRoute>} />
        <Route path="/dp/simulate/scenario/case/compare" element={<PrivateRoute path="/dp/simulate/scenario/case/compare"><DpaiFrontend />{" "}</PrivateRoute>} />
        <Route path="/dp/reports" element={<PrivateRoute path="/dp/reports"><DpaiFrontend />{" "}</PrivateRoute>} />
        <Route path="/dp/reports/details" element={<PrivateRoute path="/dp/reports/details"><DpaiFrontend />{" "}</PrivateRoute>} />

        {/* DPAI routes */}

        {/* SNP routes */}
        <Route path="/snp/reports" element={<PrivateRoute path="/snp/reports"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/reports/:id" element={<PrivateRoute path="/snp/reports/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/management" element={<PrivateRoute path="/snp/inventory/management"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/management/inventory-stats" element={<PrivateRoute path="/snp/inventory/management/inventory-stats"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/management/preprocesses" element={<PrivateRoute path="/snp/inventory/management/preprocesses"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/management/preprocesses/process/:type" element={<PrivateRoute path="/snp/inventory/management/preprocesses/process/:type"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/management/job-scheduler" element={<PrivateRoute path="/snp/inventory/management/job-scheduler"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/management/job-scheduler-create" element={<PrivateRoute path="/snp/inventory/management/job-scheduler-create"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/management/job-scheduler-history/:id" element={<PrivateRoute path="/snp/inventory/management/job-scheduler-history/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/management/job-scheduler-edit/:id" element={<PrivateRoute path="/snp/inventory/management/job-scheduler-edit/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/management/config" element={<PrivateRoute path="/snp/inventory/management/config"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/management/config-create" element={<PrivateRoute path="/snp/inventory/management/config-create"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/management/config-edit/:id" element={<PrivateRoute path="/snp/inventory/management/config-edit/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/management/network" element={<PrivateRoute path="/snp/inventory/management/network"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/replenishment-plan" element={<PrivateRoute path="/snp/inventory/replenishment-plan"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/replenishment-plan/org-plan/:planId" element={<PrivateRoute path="/snp/inventory/replenishment-plan/org-plan/:planId"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/replenishment-plan/group-plan/:planId" element={<PrivateRoute path="/snp/inventory/replenishment-plan/group-plan/:planId"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/replenishment-plan/preprocesses" element={<PrivateRoute path="/snp/inventory/replenishment-plan/preprocesses"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/replenishment-plan/preprocesses/process/:type" element={<PrivateRoute path="/snp/inventory/replenishment-plan/preprocesses/process/:type"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/replenishment-plan/job-scheduler" element={<PrivateRoute path="/snp/inventory/replenishment-plan/job-scheduler"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/replenishment-plan/job-scheduler-create" element={<PrivateRoute path="/snp/inventory/replenishment-plan/job-scheduler-create"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/replenishment-plan/job-scheduler-history/:id" element={<PrivateRoute path="/snp/inventory/replenishment-plan/job-scheduler-history/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/replenishment-plan/job-scheduler-edit/:id" element={<PrivateRoute path="/snp/inventory/replenishment-plan/job-scheduler-edit/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/replenishment-plan/config" element={<PrivateRoute path="/snp/inventory/replenishment-plan/config"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/replenishment-plan/config-create" element={<PrivateRoute path="/snp/inventory/replenishment-plan/config-create"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/replenishment-plan/config-edit/:id" element={<PrivateRoute path="/snp/inventory/replenishment-plan/config-edit/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/replenishment-plan/network" element={<PrivateRoute path="/snp/inventory/replenishment-plan/network"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/requirement" element={<PrivateRoute path="/snp/production/requirement"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/requirement/preprocesses" element={<PrivateRoute path="/snp/production/requirement/preprocesses"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/requirement/job-scheduler" element={<PrivateRoute path="/snp/production/requirement/job-scheduler"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/requirement/job-scheduler-create" element={<PrivateRoute path="/snp/production/requirement/job-scheduler-create"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/requirement/job-scheduler-history/:id" element={<PrivateRoute path="/snp/production/requirement/job-scheduler-history/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/requirement/job-scheduler-edit/:id" element={<PrivateRoute path="/snp/production/requirement/job-scheduler-edit/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/requirement/config" element={<PrivateRoute path="/snp/production/requirement/config"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/requirement/config-create" element={<PrivateRoute path="/snp/production/requirement/config-create"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/requirement/config-edit" element={<PrivateRoute path="/snp/production/requirement/config-edit/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/requirement/network" element={<PrivateRoute path="/snp/production/requirement/network"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/production-plan" element={<PrivateRoute path="/snp/production/production-plan"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/production-plan/app-group-level-view/:id" element={<PrivateRoute path="/snp/production/production-plan/app-group-level-view/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/production-plan/app-org-level-view/:id" element={<PrivateRoute path="/snp/production/production-plan/app-org-level-view/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/production-plan/app-group-level-view/:id/add-pwo" element={<PrivateRoute path="/snp/production/production-plan/app-group-level-view/:id/add-pwo"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/requirement" element={<PrivateRoute path="/snp/raw-material/requirement"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/requirement/preprocesses" element={<PrivateRoute path="/snp/raw-material/requirement/preprocesses"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/requirement/job-scheduler" element={<PrivateRoute path="/snp/raw-material/requirement/job-scheduler"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/requirement/job-scheduler-create" element={<PrivateRoute path="/snp/raw-material/requirement/job-scheduler-create"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/requirement/job-scheduler-edit/:id" element={<PrivateRoute path="/snp/raw-material/requirement/job-scheduler-edit/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/requirement/config" element={<PrivateRoute path="/snp/raw-material/requirement/config"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/requirement/config-create" element={<PrivateRoute path="/snp/raw-material/requirement/config-create"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/requirement/config-edit/:id" element={<PrivateRoute path="/snp/raw-material/requirement/config-edit/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/requirement/network" element={<PrivateRoute path="/snp/raw-material/requirement/network"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/plan" element={<PrivateRoute path="/snp/raw-material/plan"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/management/demand-overview" element={<PrivateRoute path="/snp/inventory/management/demand-overview"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/production-plan/preprocesses" element={<PrivateRoute path="/snp/production/production-plan/preprocesses"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/production-plan/preprocesses/process/:type" element={<PrivateRoute path="/snp/production/production-plan/preprocesses/process/:type"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/production-plan/job-scheduler" element={<PrivateRoute path="/snp/production/production-plan/job-scheduler"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/production-plan/job-scheduler-create" element={<PrivateRoute path="/snp/production/production-plan/job-scheduler-create"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/production-plan/job-scheduler-history/:id" element={<PrivateRoute path="/snp/production/production-plan/job-scheduler-history/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/production-plan/job-scheduler-edit/:id" element={<PrivateRoute path="/snp/production/production-plan/job-scheduler-edit/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/production-plan/config" element={<PrivateRoute path="/snp/production/production-plan/config"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/production-plan/config-create" element={<PrivateRoute path="/snp/production/production-plan/config-create"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/production-plan/config-edit/:id" element={<PrivateRoute path="/snp/production/production-plan/config-edit/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/production/production-plan/network" element={<PrivateRoute path="/snp/production/production-plan/network"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/plan/preprocesses" element={<PrivateRoute path="/snp/raw-material/plan/preprocesses"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/plan/preprocesses/process/:type" element={<PrivateRoute path="/snp/raw-material/plan/preprocesses/process/:type"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/plan/job-scheduler" element={<PrivateRoute path="/snp/raw-material/plan/job-scheduler"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/plan/job-scheduler-create" element={<PrivateRoute path="/snp/raw-material/plan/job-scheduler-create"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/plan/job-scheduler-edit/:id" element={<PrivateRoute path="/snp/raw-material/plan/job-scheduler-edit/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/plan/config" element={<PrivateRoute path="/snp/raw-material/plan/config"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/plan/config-create" element={<PrivateRoute path="/snp/raw-material/plan/config-create"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/plan/config-edit/:id" element={<PrivateRoute path="/snp/raw-material/plan/config-edit/:id"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/raw-material/plan/network" element={<PrivateRoute path="/snp/raw-material/plan/network"><SnpFrontend />{" "}</PrivateRoute>} />
        <Route path="/snp/inventory/replenishment-plan/group-plan/:planId/add-sto" element={<PrivateRoute path="/snp/inventory/replenishment-plan/group-plan/:planId/add-sto"><SnpFrontend />{" "}</PrivateRoute>} />
        {/* SNP routes */}

        {/* Carbon X Routing */}
        <Route path="/carbon-analytics/executive-summary" element={<PrivateRoute path="/carbon-analytics/executive-summary"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/carbon-analytics/detailed-summary" element={<PrivateRoute path="/carbon-analytics/detailed-summary"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/carbon-analytics/equivalence" element={<PrivateRoute path="/carbon-analytics/equivalence"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/carbon-analytics/computation" element={<PrivateRoute path="/carbon-analytics/computation"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/carbon-analytics/computation/scope" element={<PrivateRoute path="/carbon-analytics/computation/scope"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/carbon-analytics/singleview" element={<PrivateRoute path="/carbon-analytics/singleview"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-1" element={<PrivateRoute path="/entity-management/transactional/scope-1"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-1-1" element={<PrivateRoute path="/entity-management/transactional/scope-1-1"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-1-2" element={<PrivateRoute path="/entity-management/transactional/scope-1-2"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-1-3" element={<PrivateRoute path="/entity-management/transactional/scope-1-3"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-2" element={<PrivateRoute path="/entity-management/transactional/scope-2"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-3-1" element={<PrivateRoute path="/entity-management/transactional/scope-3-1"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-3-2" element={<PrivateRoute path="/entity-management/transactional/scope-3-2"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-3-3" element={<PrivateRoute path="/entity-management/transactional/scope-3-3"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-3-4" element={<PrivateRoute path="/entity-management/transactional/scope-3-4"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-3-5" element={<PrivateRoute path="/entity-management/transactional/scope-3-5"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-3-6" element={<PrivateRoute path="/entity-management/transactional/scope-3-6"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-3-7" element={<PrivateRoute path="/entity-management/transactional/scope-3-7"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-3-8" element={<PrivateRoute path="/entity-management/transactional/scope-3-8"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-3-9" element={<PrivateRoute path="/entity-management/transactional/scope-3-9"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-3-10" element={<PrivateRoute path="/entity-management/transactional/scope-3-10"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-3-11" element={<PrivateRoute path="/entity-management/transactional/scope-3-11"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-3-12" element={<PrivateRoute path="/entity-management/transactional/scope-3-12"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-3-13" element={<PrivateRoute path="/entity-management/transactional/scope-3-13"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-3-14" element={<PrivateRoute path="/entity-management/transactional/scope-3-14"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/transactional/scope-3-15" element={<PrivateRoute path="/entity-management/transactional/scope-3-15"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/data-mapping-layer/upload" element={<PrivateRoute path="/data-mapping-layer/upload"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/data-mapping-layer/rule-selection" element={<PrivateRoute path="/data-mapping-layer/rule-selection"><CarbonFrontend />{" "}</PrivateRoute>} />
        <Route path="/entity-management/master/vehicle-entity" element={<PrivateRoute path="/entity-management/master/vehicle-entity"><CarbonFrontend />{" "}</PrivateRoute>} />
        {/* Carbon X Routing */}

        {/* DTRM Routing */}
        <Route path="/riskai" element={<PrivateRoute path="/riskai"><RMFrontend />{" "}</PrivateRoute>} />
        <Route path="/riskai/riskdetails" element={<PrivateRoute path="/riskai/riskdetails"><RMFrontend />{" "}</PrivateRoute>} />
        <Route path="/reports" element={<PrivateRoute path="/reports"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/resilienceoverall' element={<PrivateRoute path="/resilienceoverall"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/supplier' element={<PrivateRoute path="/supplier"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/manufacturing' element={<PrivateRoute path="/manufacturing"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/transportation' element={<PrivateRoute path="/transportation"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/warehouse' element={<PrivateRoute path="/warehouse"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/customer' element={<PrivateRoute path="/customer"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/fginventory' element={<PrivateRoute path="/fginventory"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/rminventory' element={<PrivateRoute path="/rminventory"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/demand' element={<PrivateRoute path="/demand"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/resilienceoverall/collab' element={<PrivateRoute path="/resilienceoverall/collab"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/supplierdetail' element={<PrivateRoute path="/supplierdetail"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/manufacturingdetail' element={<PrivateRoute path="/manufacturingdetail"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/transportationdetail' element={<PrivateRoute path="/transportationdetail"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/warehousedetail' element={<PrivateRoute path="/warehousedetail"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/customerdetail' element={<PrivateRoute path="/customerdetail"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/fginventorydetail' element={<PrivateRoute path="/fginventorydetail"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/rminventorydetail' element={<PrivateRoute path="/rminventorydetail"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/demanddetail' element={<PrivateRoute path="/demanddetail"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/gauri' element={<PrivateRoute path="/gauri"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/gauri/chatresponse' element={<PrivateRoute path="/gauri/chatresponse"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/worldengine' element={<PrivateRoute path="/worldengine"><RMFrontend />{" "}</PrivateRoute>} />
        <Route exact path='/simulationresults' element={<PrivateRoute path="/simulationresults"><RMFrontend />{" "}</PrivateRoute>} />

        <Route path="/dt" element={<PrivateRoute path="/dt"><DTFrontend />{" "}</PrivateRoute>} />
        <Route path="/dt/nodestats" element={<PrivateRoute path="/dt/nodestats"><DTFrontend />{" "}</PrivateRoute>} />
        <Route path="/dt/shipmentstats" element={<PrivateRoute path="/dt/shipmentstats"><DTFrontend />{" "}</PrivateRoute>} />
        {/* DTRM Routing */}

        {/* Product Routing */}
        <Route path="/pp/forecast" element={<PrivateRoute path="/pp/forecast"><ProductFrontend />{" "}</PrivateRoute>} />


        {/* Wildcard route */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
};

export default Routing;

